<template>
  <!--begin::Card-->
  <div class="card h-100" :class="cardClasses">
    <!--
      <div class="card-header flex-nowrap border-0 pt-9">
        <div class="card-title m-0">
          <div class="symbol symbol-45px w-45px bg-light me-5">
            <img :src="icon" class="p-3" />
          </div>
  
          <a class="fs-4 fw-bold text-hover-primary text-gray-600 m-0">
            {{ title }}
          </a>
        </div>
  
      </div>
      -->

    <!--begin::Card body-->
    <div class="card-body d-flex flex-column px-9 pt-5 pb-2">
      <!--begin::Heading-->
      <div class="fs-2tx fw-bolder mb-3">
        <a href="#" style="color: black"> {{ content }} </a>
      </div>
      <!--end::Heading-->

      <!--begin::Stats-->
      <div class="d-flex align-items-center flex-wrap mb-5 mt-auto fs-6">
        <span :class="`svg-icon-${color}`" class="svg-icon svg-icon-3 me-1">
          <inline-svg :src="`media/icons/duotune/Navigation/${arrow}.svg`" />
        </span>

        <div class="fw-bolder me-2">
          {{ indicatorLabel }}
        </div>

        <label class="col-lg-6 fv-row">
          <span
            v-if="capacity > animalCount"
            class="badge fw-bolder badge-light-success"
            style="font-size: 16px"
          >
            {{ animalCount }} / {{ capacity }}
          </span>
          <span
            v-else
            class="badge fw-bolder badge-light-danger"
            style="font-size: 16px"
          >
            {{ animalCount }} / {{ capacity }}</span
          >
        </label>
      </div>
      <!--end::Stats-->
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "card-2",
  props: {
    cardClasses: String,

    icon: String,

    title: String,

    content: String,

    color: String,

    arrow: String,

    capacity: Number,

    animalCount: Number,

    indicatorValue: String,

    indicatorLabel: String,
  },
});
</script>
