
import { defineComponent, onMounted, ref, watch } from "vue";
import BarnyardCard from "@/presentation/components/cards/BarnyardCard.vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { ROUTE_PAGES, SWAL_MESSAGES } from "@/domain/Constant";
import { useRouter } from "vue-router";
import { BarnyardListModel } from "@/domain/barnyard/barnyardList/model/BarnyardListModel";

export default defineComponent({
  name: "selectBarnyards",
  components: {
    BarnyardCard,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const swalNotification = new SwalNotification();
    const isLoading = ref<boolean>(false);

    const barnyardController = store.state.ControllersModule.barnyardController;
    const barnyardList = ref<BarnyardListModel[]>([]);

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    watch(
      () => store.getters.newAnimal,
      () => {
        if (store.getters.newAnimal) getBarnyardList(1);
      }
    );

    onMounted(() => {
      setCurrentPageTitle(t("menuPages.barnyards.pageName"));
      getBarnyardList(1);
    });

    const selectBarnyard = async (barnyardCode: number) => {
      router.push({
        name: ROUTE_PAGES.BARNYARD_ANIMALS,
        params: { id: barnyardCode },
      });
    };

    const getBarnyardList = async (page) => {
      isLoading.value = true;

      barnyardList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const barnyardListModel: BarnyardListModel = {
        barnyard: {},
        page: page,
        pageSize: pageSize.value,
      };

      barnyardController
        .barnyardList(barnyardListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((barnyard) => {
              totalItems.value = barnyard.pagination.total;
              currentPage.value = barnyard.pagination.page;
              pageCount.value = barnyard.pagination.pageCount;
              barnyardList.value.push(barnyard);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    return {
      selectBarnyard,
      barnyardList,
      isLoading,
      getBarnyardList,
      currentPage,
      pageSize,
      totalItems,
      pageCount,
    };
  },
});
