<template>
  <!--begin::Campaigns toolbar-->
  <div class="d-flex flex-wrap flex-stack my-5">
    <!--begin::Title-->
    <h2 class="fw-bold my-2">
      {{ $t("menuPages.barnyards.pageName") }}
      <!--    <span class="fs-4 text-gray-400 ms-1">(5)</span>-->
    </h2>
    <!--end::Title-->

    <!--
    <div class="d-flex align-items-center my-2">
      <div class="w-100px me-5">
        <select
          name="status"
          data-control="select2"
          data-hide-search="true"
          class="form-select form-select-white form-select-sm"
        >
          <option value="1" selected>5</option>
          <option value="2">10</option>
          <option value="3">20</option>
          <option value="4">50</option>
        </select>
      </div>

      <button
        class="btn btn-primary btn-sm"
        data-bs-toggle="tooltip"
        title="Coming soon"
      >
        {{ $t("menuPages.barnyards.show") }}
      </button>
    </div>
-->
  </div>
  <!--end::Campaigns toolbar-->
  <div class="row g-6 g-xl-9">
    <div
      class="col-sm-6 col-xl-4"
      v-for="barnyard in barnyardList"
      :key="barnyard.barnyard.id"
    >
      <div class="card h-100">
        <!--        <router-link to=" /barnyardAnimals">
          <Card2
            @click="selectBarnyard(barnyard.attributes.shortCode)"
            card-classes="h-100"
           :title="$t('menuPages.barnyards.barnyard')"
           icon="media/svg/1200px-Cowicon.svg.png"
            :content="barnyard.attributes.name"
            color="success"
            arrow="Up-right"
            :footer-data="barnyard.attributes.capacity"
            footer-text="kapasiteli"
            indicator-value="(count) hayvan"
          ></Card2
        ></router-link>
      -->
        <!--<Card2
            @click="selectBarnyard(barnyard.id)"
            card-classes="h-100"
            :content="barnyard.attributes.name"
            color="success"
            arrow="Up-right"
            :footer-data="barnyard.attributes.capacity"
            footer-text="kapasiteli"
            indicator-value="(count) hayvan"
          ></Card2>-->
        <BarnyardCard
          @click="selectBarnyard(barnyard.barnyard.id)"
          card-classes="h-100"
          :content="barnyard.barnyard.name"
          color="success"
          arrow="Up-right"
          :animalCount="barnyard.barnyard.animalCount"
          :capacity="barnyard.barnyard.capacity"
          indicatorLabel="Doluluk:"
        ></BarnyardCard>
      </div>
    </div>
    <div
      v-if="isLoading"
      style="
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="text-center px-5 pb-7">
        <img
          alt="Logo"
          class="max-h-35px"
          src="/media/loading_icon.gif"
          style="width: 40px"
        />
      </div>
    </div>
    <div v-else-if="barnyardList.length == 0" class="card-px text-center">
      <div class="text-center px-5 pb-7">
        <img
          src="/media/animal/barnyard.jpg"
          class="symbol symbol-100px symbol-lg-160px mw-100 mh-300px"
        />
      </div>

      <!--begin::Description-->
      <p class="text-gray-400 fs-5 fw-bold">
        <span>{{ $t("menuPages.barnyards.zeroBarnyard") }}</span>
      </p>
      <!--end::Description-->
    </div>
  </div>

  <!--begin::Pagination-->
  <div
    class="d-flex flex-stack flex-wrap pt-10"
    v-if="barnyardList.length != 0 && barnyardList.length >= 5"
  >
    <!--begin::Controls-->
    <div class="d-flex flex-wrap my-1">
      <!--begin::Select wrapper-->
      <div class="m-0">
        <!--begin::Select-->
        <select
          name="status"
          data-control="select2"
          data-hide-search="true"
          class="form-select form-select-white form-select-sm fw-bolder w-125px"
          v-model="pageSize"
          @change="getBarnyardList(1)"
        >
          <option value="5">5</option>
          <option value="10" selected>10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
        <span>{{ $t("common.table.pagination.desc2") }}</span>

        <!--end::Select-->
      </div>
      <!--end::Select wrapper-->
    </div>
    <!--end::Controls-->

    <div class="fs-6 fw-bold text-gray-700">
      {{ totalItems }} {{ $t("common.table.pagination.desc") }}
      {{ (currentPage - 1) * pageSize + 1 }} -
      {{ Math.min(currentPage * pageSize, totalItems) }}
      {{ $t("common.table.pagination.desc1") }}
    </div>

    <!--begin::Pages-->
    <ul class="pagination">
      <li
        class="page-item previous"
        @click="getBarnyardList(currentPage - 1)"
        :class="{ disabled: currentPage === 1 }"
      >
        <a href="#" class="page-link"><i class="previous"></i></a>
      </li>

      <li
        v-for="page in pageCount"
        :key="page"
        class="page-item"
        :class="{ active: currentPage === page }"
      >
        <a href="#" class="page-link" @click="getBarnyardList(page)">{{
          page
        }}</a>
      </li>

      <li
        class="page-item next"
        @click="getBarnyardList(currentPage + 1)"
        :class="{ disabled: currentPage === pageCount }"
      >
        <a href="#" class="page-link"><i class="next"></i></a>
      </li>
    </ul>
    <!--end::Pages-->
  </div>
  <!--end::Pagination-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import BarnyardCard from "@/presentation/components/cards/BarnyardCard.vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { ROUTE_PAGES, SWAL_MESSAGES } from "@/domain/Constant";
import { useRouter } from "vue-router";
import { BarnyardListModel } from "@/domain/barnyard/barnyardList/model/BarnyardListModel";

export default defineComponent({
  name: "selectBarnyards",
  components: {
    BarnyardCard,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const swalNotification = new SwalNotification();
    const isLoading = ref<boolean>(false);

    const barnyardController = store.state.ControllersModule.barnyardController;
    const barnyardList = ref<BarnyardListModel[]>([]);

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    watch(
      () => store.getters.newAnimal,
      () => {
        if (store.getters.newAnimal) getBarnyardList(1);
      }
    );

    onMounted(() => {
      setCurrentPageTitle(t("menuPages.barnyards.pageName"));
      getBarnyardList(1);
    });

    const selectBarnyard = async (barnyardCode: number) => {
      router.push({
        name: ROUTE_PAGES.BARNYARD_ANIMALS,
        params: { id: barnyardCode },
      });
    };

    const getBarnyardList = async (page) => {
      isLoading.value = true;

      barnyardList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const barnyardListModel: BarnyardListModel = {
        barnyard: {},
        page: page,
        pageSize: pageSize.value,
      };

      barnyardController
        .barnyardList(barnyardListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((barnyard) => {
              totalItems.value = barnyard.pagination.total;
              currentPage.value = barnyard.pagination.page;
              pageCount.value = barnyard.pagination.pageCount;
              barnyardList.value.push(barnyard);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    return {
      selectBarnyard,
      barnyardList,
      isLoading,
      getBarnyardList,
      currentPage,
      pageSize,
      totalItems,
      pageCount,
    };
  },
});
</script>
